import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout';
//import SEO from "../components/seo"

const showdown = require('showdown');
const converter = new showdown.Converter();


const MarkDown = ({md, classes}) => {
    return (
        // <div dangerouslySetInnerHTML={{ __html: marked(md) }} />
        <div className={classes?classes:""} dangerouslySetInnerHTML={{ __html: converter.makeHtml(md) }} />
    );
};

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "homePage"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              topTitle
              subtitle1
              subtitle2
              content
            }
          }
        }
      }
    }
  }`;

const IndexPage = (props) => {
    const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter;
    return (
        <Layout pageName = "homePage">
            <div className="container-fluid" id="main">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="left-index">
                            <h3><MarkDown md={data.topTitle}/></h3>
                            <h2><strong>{data.title}</strong></h2>
                            <MarkDown md={data.subtitle1} classes="homeServices"/>
                            <h3><MarkDown md={data.subtitle2}/></h3>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="right-index" style={{textAlign: 'right'}}>
                            <MarkDown md={data.content}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default IndexPage
